.reactMarkDown p {
  margin: 0;
  white-space: pre-wrap;
  line-height: 1.6em;
}

.reactMarkDown {
  white-space: pre-wrap;
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
}

.reactMarkDown h1 {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
}

.reactMarkDown h2 {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
}

.reactMarkDown ul {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
}

.MuiDialog-paper {
  background-color: white;
  border-radius: 12px;
  isolation: isolate;
  flex-direction: column;
  gap: 2px;
  padding: 2px 12px;
  opacity: 1;
  transform: none;
}
