.bn-container {
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;
  margin-top: 16px;
}

.editorContainer {
  width: 70%;
  margin: 0 auto;
}

.bn-formatting-toolbar {
  margin-inline: auto;
  gap: 8px !important;
  width: 100% !important;
  padding: 8px !important;
  box-shadow: 0 0 0;
  font-size: 8px;
}

.reactMarkDown h3 {
  margin-block: 0;
}

.reactMarkDown ol {
  margin-block: 0;
}

.reactMarkDown li {
  margin-block: 0;
}

.reactMarkDown ul {
  margin-block: 0;
}

.reactMarkDown ol li {
  display: block; /* Override the list-item display */
}

.reactMarkDown ol {
  counter-reset: list-counter;
}

.reactMarkDown ol li p {
  position: relative;
  padding-left: 2em; /* Adjust space for the number */
}

.reactMarkDown ol li p::before {
  content: counter(list-counter) ".";
  counter-increment: list-counter;
  position: absolute;
  left: 0;
  top: 0;
}
