.reactMarkDown p {
  margin: 0;
  /*white-space: pre-wrap;*/
  font-size: 14px;
}

.reactMarkDown {
  /*white-space: pre-wrap;*/
  width: 100%;
}
